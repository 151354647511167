<template>
    <div class="course_succeed_">
        <header>
            <div class="banner_img_box_">
                <img src="https://cdn.zhiyileiju.cn/WechatIMG1079.png" alt="">
            </div>
        </header>
        <section>
            <h1 class="title_text_">进行课程激活</h1>
            <p class="p_text_">长按二维码识别添加老师领取学习地址</p>
            <div class="QR_code_"><img src="https://cdn.zhiyileiju.cn/WechatIMG187.jpeg" alt=""></div>
            <p class="p_text_" style="margin-top:.39rem;">发送添加请求后将会在24小时内通过</p>
        </section>
        <footer></footer>
    </div>
</template>

<script>
export default {
    data () {
        return{}
    },
    created() {
        this.callback()
    },
    methods:{
       callback() {
           _baq.track("form", { assets_id: "1721732743264334", product_name: '课程', product_price: 100 })
       } 
    }
}
</script>

<style lang="scss" scoped>
.course_succeed_{
    header{
        .banner_img_box_{
            width: 7.5rem;
        }
    }
    section{
        .title_text_{
            margin-top: .3rem;
            text-align: center;
            font-size: .36rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #151515;
        }
        .p_text_{
            text-align: center;
            font-size: .24rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #757575;
            margin-top: .24rem;
        }
        .QR_code_{
            width: 2.46rem;
            height: 2.46rem;
            // background: #757575;
            margin: auto;
            margin-top: .45rem;
        }
    }
}
</style>